/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
const t = new Float32Array(1);
function n(t) {
  --t;
  for (let n = 1; n < 32; n <<= 1) t |= t >> n;
  return t + 1;
}
function r(t, n, r) {
  return Math.min(Math.max(t, n), r);
}
function e(t, n) {
  return 0 === n ? 0 : Math.round(t / n) * n;
}
function u(t) {
  return !(t & t - 1);
}
function i(t) {
  return t--, t |= t >> 1, t |= t >> 2, t |= t >> 4, t |= t >> 8, t |= t >> 16, ++t;
}
function a(t) {
  return 10 ** Math.ceil(Math.LOG10E * Math.log(t));
}
function o(t, n, r) {
  return t + (n - t) * r;
}
function f(t, n) {
  const r = -t * t / (2 * n);
  return 1 / Math.sqrt(2 * Math.PI * n) * Math.exp(r);
}
function c(t, n, r, e, u) {
  return o(e, u, (t - n) / (r - n));
}
function s(t) {
  return t * Math.PI / 180;
}
function M(t) {
  return 180 * t / Math.PI;
}
function h(t, n = 1e-6) {
  return (t < 0 ? -1 : 1) / Math.max(Math.abs(t), n);
}
function b(t) {
  return Math.acos(r(t, -1, 1));
}
function l(t) {
  return Math.asin(r(t, -1, 1));
}
function m(t, n, r = 1e-6) {
  if (t === n) return !0;
  if (!Number.isFinite(t) || !Number.isFinite(n)) return !1;
  return (t > n ? t - n : n - t) <= r;
}
const N = new DataView(new ArrayBuffer(Float64Array.BYTES_PER_ELEMENT));
function F(t) {
  return N.setFloat64(0, t), N.getBigInt64(0);
}
function g(t) {
  return N.setBigInt64(0, t), N.getFloat64(0);
}
const E = BigInt("1000000"),
  I = p(1);
function p(t) {
  const n = F(t = Math.abs(t)),
    r = g(n <= E ? E : n - E);
  return Math.abs(t - r);
}
function x(t, n, r = I) {
  if (t === n) return !0;
  if (!Number.isFinite(t) || !Number.isFinite(n)) return !1;
  if (null != r) {
    if (p(Math.min(Math.abs(t), Math.abs(n))) < r) return Math.abs(t - n) <= r;
  }
  const e = F(t),
    u = F(n);
  if (e < 0 != u < 0) return !1;
  return !((e < u ? u - e : e - u) > E);
}
function B(t, n, r = 1e-6) {
  if (t === n) return !0;
  if (!Number.isFinite(t) || !Number.isFinite(n)) return !1;
  const e = Math.abs(t - n),
    u = Math.abs(t),
    i = Math.abs(n);
  if (0 === t || 0 === n || u < 1e-12 && i < 1e-12) {
    if (e > .01 * r) return !1;
  } else if (e / (u + i) > r) return !1;
  return !0;
}
function w(t) {
  return y(Math.max(-d, Math.min(t, d)));
}
function y(n) {
  return t[0] = n, t[0];
}
function P(t, n, e) {
  const u = r((e - t) / (n - t), 0, 1);
  return u * u * (3 - 2 * u);
}
function A(t) {
  const n = t[0] * t[0] + t[1] * t[1] + t[2] * t[2],
    r = t[3] * t[3] + t[4] * t[4] + t[5] * t[5],
    e = t[6] * t[6] + t[7] * t[7] + t[8] * t[8];
  return !(m(n, 1) && m(r, 1) && m(e, 1));
}
function S(t, n) {
  return (t % n + n) % n;
}
const d = y(34028234663852886e22);
function L(t, n, r) {
  if (void 0 === r || 0 == +r) return Math[t](n);
  if (n = +n, r = +r, isNaN(n) || "number" != typeof r || r % 1 != 0) return NaN;
  let e = n.toString().split("e");
  return e = (n = Math[t](+(e[0] + "e" + (e[1] ? +e[1] - r : -r)))).toString().split("e"), +(e[0] + "e" + (e[1] ? +e[1] + r : r));
}
export { b as acosClamped, l as asinClamped, r as clamp, w as clampFloat32, L as decimalAdjust, s as deg2rad, m as floatEqualAbsolute, B as floatEqualRelative, x as floatEqualUlp, f as gauss, A as hasScaling, u as isPowerOfTwo, o as lerp, S as moduloPositive, a as nextHighestPowerOfTen, n as nextHighestPowerOfTwo, i as nextPowerOfTwo, d as numberMaxFloat32, M as rad2deg, h as reciprocalClamped, e as roundToNearest, c as scale, P as smoothstep };